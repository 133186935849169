

import React, {Suspense, useContext, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
// import ForgetPassword from '../components/forgetPassword/forgetPassword';
// import VerifyOtp from '../components/forgetPassword/varify-otp';
// import ResetPassword from '../components/forgetPassword/resetPassword';
import NotFound from '../components/notFound/notFound';
import { UserContext } from '../context/theme';
// import Header from '../layout/header';
// import TopNavbar from '../layout/topNavbar';
// import Profile from '../pages/account/profile';
// import BankDetails from '../pages/account/bankDetails';
// import ChangePassword from '../pages/account/changePass';
// import Dashboard from '../pages/dashboard';
import Login from '../pages/login';
// import Register from '../pages/register';
// import Order from '../pages/order/order';
// import Table from '../pages/tableSetup/table-setup';
// import View from '../pages/order/view';
// import Menu from '../pages/menu';
// import Product from '../pages/product/product-master';
// import ProductAdd from '../pages/product/add';
// import Transaction from '../pages/transaction/transactions-report';
// import TransactionView from '../pages/transaction/transaction-view';
// import FormBlock from '../components/form/FormBlock';
// const  Login = React.lazy(()=>import('../pages/login'));
// const  Register = React.lazy(()=>import('../pages/register'));


function UnAuth() {
  const context = useContext(UserContext)

  
  return (
    <>
    <div className={`page-wrapper ${context.Theme} ${context.ThemeColor} pageLogin`}>
    <div className="login-wrapper d-flex">
   
     
   
            <Suspense fallback={'loading'}>
              <Routes>
               
                <Route path="*" element={<Login/>}/>
                {/* <Route path="/register" element={<Register/>}/>
                <Route path="/forget-pass" element={<ForgetPassword/>}/>
                <Route path="/verify-otp" element={<VerifyOtp/>}/>
                <Route path="/reset-pass" element={<ResetPassword/>}/> */}
               
               
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
        </div>
       
        </>
    
  );
}

export default UnAuth;
